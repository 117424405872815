import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import UpdatedTechnology from "./../../Assets/Images/UpdatedTechnology.gif";

function TechnologyArea() {
  return (
    <section className="technology-area ptb-100 bg-color">
      <div className="container">
        <div className="row align-items-center choose-c">
          <div className="col-lg-6">
            <div className="choose-img">
              <img src={UpdatedTechnology} alt="choose" />
              <div className="technology-video">
                <a
                  href="https://www.youtube.com/watch?v=TdSA7gkVYU0"
                  className="video-btn popup-youtube"
                >
                  <i className="bx bx-play"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="technology-content">
              <h2>{window.t("technologyTitle")}</h2>
              <p>{window.t("technologyDesc1")}</p>
              <p>{window.t("technologyDesc2")}</p>

              <Link to={"/about"} href="contact.html" className="box-btn">
                {window.t("about")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechnologyArea;
