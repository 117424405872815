import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import helpers from "./../../Constants/helpers";
import Animation from "./Animation";
import { useLocation } from "react-router";

const MobileNavbar = () => {
  const location = useLocation();
  const [showAllNavs, setShowAllNavs] = useState(false);

  const runAOSClass = () => {
    setShowAllNavs(true);
  };
  const stopAOSClass = () => {
    setShowAllNavs(false);
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      function () {
        var top = this.scrollY;
        const navbar = document.querySelector(".mobileNavbar");
        if (top >= 100) {
          navbar.classList.add("stickyAndWhiteBG");
        } else {
          navbar.classList.remove("stickyAndWhiteBG");
        }
      },
      false
    );
    //close collapse on click nav item
    let getNav = document.querySelector(".mobileNavbarCollapse");
    if (getNav === null) {
      console.log("");
    } else {
      setShowAllNavs(false);
      if (getNav.classList.contains("show")) {
        getNav.classList.remove("show");
      }
    }
  }, [location]);

  return (
    <nav className="navbar mobileNavbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link to={"/"} className="logo navbar-brand">
          <img src={helpers.mainSmallLogo()} alt="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ border: "none" }}
          onClick={runAOSClass}
        >
          <i className="fas fa-bars fa-xl text-white"></i>
        </button>
        <div
          className="collapse mobileNavbarCollapse e animate__animated animate__fadeIn navbar-collapse"
          id="navbarNav"
        >
          <button
            className="navbar-toggler animate__animated animate__fadeInDown"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ border: "none" }}
            onClick={stopAOSClass}
          >
            <i className="fas fa-close fa-xl text-white"></i>
          </button>

          {showAllNavs == true ? (
            <div className="allNavs">
              <Animation animate="fade-up" delay={400}>
                <h1>
                  <Link to={"/"} className="nav-link">
                    {window.t("home")}
                  </Link>
                </h1>
              </Animation>
              <Animation animate="fade-up" delay={600}>
                <h1>
                  <a href="#our-solutions" className="nav-link">
                    {window.t("solutions")}
                  </a>
                </h1>
              </Animation>
              <Animation animate="fade-up" delay={800}>
                <h1>
                  <a href="#our-products" className="nav-link">
                    {window.t("ourProducts")}
                  </a>
                </h1>
              </Animation>
              <Animation animate="fade-up" delay={1000}>
                <h1>
                  <a href="#our-clients" className="nav-link">
                    {window.t("clientTitle")}
                  </a>
                </h1>
              </Animation>
              <Animation animate="fade-up" delay={1200}>
                <h1>
                  <Link to={"/about"} className="nav-link">
                    {window.t("about")}
                  </Link>
                </h1>
              </Animation>
              <Animation animate="fade-up" delay={1400}>
                <h1>
                  <a href="#contact-us" className="nav-link">
                  {window.t("contactUsTitle")}
                  </a>
                </h1>
              </Animation>
              
            </div>
          ) : null}

          {/* <div className="navbar-area">
            <div className="mobile-nav mean-container">
              <nav className="mean-nav">
                <ul className="navbar-nav text-center">
                  <li className="nav-item">
                    <Link to={"/"} className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/about"} className="nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Solutions
                    </a>
                    <ul className="dropdown-menu" style={{ display: "none" }}>
                      <li className="nav-item">
                        <a href="solutions.html" className="nav-link">
                          Solutions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="solutions-details.html" className="nav-link">
                          Solutions Details
                        </a>
                      </li>
                    </ul>
                    <a
                      className="mean-expand"
                      href="#"
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Case Studies
                    </a>
                    <ul className="dropdown-menu" style={{ display: "none" }}>
                      <li className="nav-item">
                        <a href="case.html" className="nav-link">
                          Case Studies
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="case-details.html" className="nav-link">
                          Case Studies Details
                        </a>
                      </li>
                    </ul>
                    <a
                      className="mean-expand"
                      href="#"
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Blog
                    </a>
                    <ul className="dropdown-menu" style={{ display: "none" }}>
                      <li className="nav-item">
                        <a href="blog.html" className="nav-link">
                          Blog
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="blog-details.html" className="nav-link">
                          Blog Details
                        </a>
                      </li>
                    </ul>
                    <a
                      className="mean-expand"
                      href="#"
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="nav-link dropdown-toggle">
                      Pages
                    </a>
                    <ul className="dropdown-menu" style={{ display: "none" }}>
                      <li className="nav-item">
                        <a href="team.html" className="nav-link">
                          Team
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="pricing.html" className="nav-link">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="gallery.html" className="nav-link">
                          Gallery
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="testimonials.html" className="nav-link">
                          Testimonials
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="singnup.html" className="nav-link">
                          Sign Up
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="signin.html" className="nav-link">
                          Sign In
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="404.html" className="nav-link">
                          Error 404
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="faq.html" className="nav-link">
                          FAQ
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="terms-condition.html" className="nav-link">
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="privecy.html" className="nav-link">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                    <a
                      className="mean-expand"
                      href="#"
                      style={{ fontSize: "18px" }}
                    >
                      +
                    </a>
                  </li>
                  <li className="nav-item mean-last">
                    <a href="contact.html" className="nav-link">
                      Contact{" "}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default MobileNavbar;
