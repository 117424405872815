import React, { useEffect, useState, useRef } from "react";
import helpers from "./../Constants/helpers";

function SolutionDetails() {
  return (
    <div className="solution-details">
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Solutions Details</h2>
          </div>
        </div>
        <div className="page-shape">
          <div className="shape1">
            <img src={helpers.getShape(1)} alt="shape" />
          </div>
          <div className="shape3">
            <img src={helpers.getShape(3)} alt="shape" />
          </div>
          <div className="shape4">
            <img src={helpers.getShape(4)} alt="shape" />
          </div>
          <div className="shape5">
            <img src={helpers.getShape(5)} alt="shape" />
          </div>
          <div className="shape6">
            <img src={helpers.getShape(6)} alt="shape" />
          </div>
        </div>
      </div>
      <section className="services-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="services-img mb">
                <img src={helpers.getImageUrl()} alt="Image" />
              </div>
            </div>

            <div className="col-12">
              <div className="services-details-text">
                <h2>IT Management</h2>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum not
                  only five centuries, but also the leap into.
                </p>

                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words,
                  combined with a handful of model sentence structures, to
                  generate Lorem Ipsum which looks reasonable. The generated
                  Lorem Ipsum is therefore always free from repetition, injected
                  humour, or non-characteristic words etc. this the first true
                  generator on the Internet. It uses a dictionary of over 200
                  Latin words, combined with a dictionary of over 200 Latin
                  words, combined with a
                </p>

                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words,
                  combined with a handful of model sentence structures, to
                  generate Lorem Ipsum which looks reasonable. The generated
                  Lorem Ipsum is therefore always free from repetition, injected
                  humour, or non-characteristic words etc generators on the
                  Internet tend to repeat predefined chunks as necessary, making
                  this the first generator dictionary of over 200 Latin words,
                  combined with a.
                </p>
              </div>
            </div>
          </div>

          <div className="scrives-item-2 mt-4 ">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="services-img mb-qc">
                  <img src={helpers.getImageUrl()} alt="Image" />
                </div>
              </div>

              <div className="col-lg-8">
                <h3>QA Testing</h3>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words,
                  combined with a handful of model sentence structures, to
                  generate Lorem Ipsum which looks reasonable. The generated
                  Lorem Ipsum is therefore always free from repetition
                  necessary, making this the first as necessary, making this
                </p>
              </div>
            </div>
          </div>

          <div className="scrives-item-3 mt-4">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-6">
                <div className="social">
                  <ul className="social-link">
                    <li>
                      <a href="#">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bx bxl-pinterest-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="bx bxl-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6 col-sm-6">
                <div className="share">
                  <a href="#">
                    <i className="bx bx-share-alt"></i>
                    Share
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SolutionDetails;
