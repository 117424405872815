import React, { useEffect, useState, useRef } from "react";
import Animation from "../Shared/Animation";

function Process() {
  return (
    <section className="home-process-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>{window.t("processTitle")}</span>
          <h2>{window.t("processTitleBig")}</h2>
          <p>{window.t("processDesc")}</p>
        </div>
        <div className="row" style={{ direction: "ltr" }}>
          <div className="col-lg-3 col-sm-6">
            <Animation animate="fade-up" delay={100}>
              <div className="single-process">
                <div className="icon">
                  <img src="assets/images/process/p1.png" alt="process" />
                  <span>
                    <img src="assets/images/process/next2.png" alt="shape" />
                  </span>
                </div>
                <div className="content">
                  <h3>{window.t("processChildTitle1")}</h3>
                  <p>{window.t("processChildDesc1")}</p>
                </div>
              </div>
            </Animation>
          </div>
          <div className="col-lg-3 col-sm-6">
            <Animation animate="fade-down" delay={200}>
              <div className="single-process">
                <div className="icon">
                  <img src="assets/images/process/p2.png" alt="process" />
                  <span className="pro-span">
                    <img src="assets/images/process/next2.png" alt="shape" />
                  </span>
                </div>
                <div className="content">
                  <h3>{window.t("processChildTitle2")}</h3>
                  <p>{window.t("processChildDesc2")}</p>
                </div>
              </div>
            </Animation>
          </div>
          <div className="col-lg-3 col-sm-6">
            <Animation animate="fade-up" delay={300}>
              <div className="single-process">
                <div className="icon">
                  <img src="assets/images/process/p3.png" alt="process" />
                  <span>
                    <img src="assets/images/process/next2.png" alt="shape" />
                  </span>
                </div>
                <div className="content">
                  <h3>{window.t("processChildTitle3")}</h3>
                  <p>{window.t("processChildDesc3")}</p>
                </div>
              </div>
            </Animation>
          </div>
          <div className="col-lg-3 col-sm-6">
            <Animation animate="fade-down" delay={400}>
              <div className="single-process">
                <div className="icon">
                  <img src="assets/images/process/p4.png" alt="process" />
                </div>
                <div className="content">
                  <h3>{window.t("processChildTitle4")}</h3>
                  <p>{window.t("processChildDesc4")}</p>
                </div>
              </div>
            </Animation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
