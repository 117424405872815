import React, { useEffect, useState, useRef } from "react";
import app from "../../firebase";
import { uid } from "uid";
import {
  getFirestore,
  collection,
  Timestamp,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore/lite";
import { contactus } from "../../helpers/collectionNames";

function ContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const initState = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    date: Timestamp.now(),
  };
  const [state, setState] = useState(initState);

  const addContactUsMessage = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let id = uid(16);
    const db = getFirestore(app);
    const groceriesColRef = doc(collection(db, contactus), id);
    await setDoc(groceriesColRef, {
      id: id,
      ...state,
    })
      .then(() => {
        setIsLoading(false);
        setState(initState);
        document.getElementById("contactForm").reset();
      })
      .catch(() => {
        setIsLoading(false);
        setError(true);
      });
  };

  return (
    <section className="home-contact-area home-2-contact ptb-100" id="contact-us">
      <div className="container">
        <div className="section-title">
          <span>{window.t("contactUsTitle")}</span>
          <h2>{window.t("contactUsTitleBig")}</h2>
          <p>{window.t("contactUsDesc")}</p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="content">
              <form id="contactForm" onSubmit={addContactUsMessage}>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="form-control"
                        onChange={(e) => {
                          setState({
                            ...state,
                            name: e.target.value,
                          });
                        }}
                        required
                        data-error="Please enter your name"
                        placeholder={window.t("contactUsChildInput1")}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-sm-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        onChange={(e) => {
                          setState({
                            ...state,
                            email: e.target.value,
                          });
                        }}
                        required
                        data-error="Please enter your email"
                        placeholder={window.t("contactUsChildInput2")}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        required
                        data-error="Please enter your number"
                        className="form-control"
                        onChange={(e) => {
                          setState({
                            ...state,
                            phone: e.target.value,
                          });
                        }}
                        placeholder={window.t("contactUsChildInput3")}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="msg_subject"
                        id="msg_subject"
                        className="form-control"
                        onChange={(e) => {
                          setState({
                            ...state,
                            subject: e.target.value,
                          });
                        }}
                        required
                        data-error="Please enter your subject"
                        placeholder={window.t("contactUsChildInput4")}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        onChange={(e) => {
                          setState({
                            ...state,
                            message: e.target.value,
                          });
                        }}
                        id="message"
                        cols="30"
                        rows="5"
                        required
                        data-error="Write your message"
                        placeholder={window.t("contactUsChildInput5")}
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="default-btn page-btn box-btn"
                    >
                      {isLoading
                        ? window.t("contactUsSending")
                        : window.t("contactUsChildInput6")}
                    </button>
                    <div id="msgSubmit" className="h3 text-center hidden"></div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="contact-img contact-img-2"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
