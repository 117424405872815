import "./App.css";
import Routing from "./Router/index";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();
  window.t = t;
  window.lang = i18n;

  return (
    <div className="app">
      <Routing />
    </div>
  );
}

export default App;
