import React, { useEffect, useState, useRef } from "react";
import helpers from "./../Constants/helpers";
function Team() {
  return (
    <div id="team">
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Team</h2>
          </div>
        </div>
        <div className="page-shape">
          <div className="shape1">
            <img src={helpers.getShape(1)} alt="shape" />
          </div>
          <div className="shape3">
            <img src={helpers.getShape(3)} alt="shape" />
          </div>
          <div className="shape4">
            <img src={helpers.getShape(4)} alt="shape" />
          </div>
          <div className="shape5">
            <img src={helpers.getShape(5)} alt="shape" />
          </div>
          <div className="shape6">
            <img src={helpers.getShape(6)} alt="shape" />
          </div>
        </div>
      </div>
      <section className="team-area pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2>Our Latest Products</h2>
            <p>
              We always try to implement creative ideas at the highest level.
              You can see it by looking at our portfolio.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g1.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Evana Doe</h3>
                  <p>Founder</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g3.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Maria Doe</h3>
                  <p>Manager</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g2.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Smith Doe</h3>
                  <p>CEO</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g5.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Leo Doe</h3>
                  <p>Project Manager</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g4.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Jhon Doe</h3>
                  <p>Marketing Support</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team">
                <div className="team-img">
                  <img src="assets/images/team/g6.jpg" alt="team" />
                </div>
                <div className="team-content">
                  <h3>Brian Cox</h3>
                  <p>Agent Management</p>
                  <ul className="social">
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxs-envelope"></i>
                      </a>
                    </li>
                    <li>
                      <a href="# " target="_blank">
                        <i className="bx bxl-google-plus"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Team;
