import React, { useEffect, useState, useRef } from "react";
import helpers from "./../../Constants/helpers";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./Language.css";

const lngs = {
  en: { nativeName: "English" },
  ku: { nativeName: "كوردی" },
  ar: { nativeName: "العربية" },
};

function Footer() {
  const { i18n } = useTranslation();
  var footerRtlStyle = classNames("footer-area footer-area-2 pt-100", {
    "footer-area-rtl":
      i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  });
  const changeStyleOnClick = () => {
    window.location.reload();
  };
  const changestyle = () => {
    if (i18n.resolvedLanguage == "ar" || i18n.resolvedLanguage == "ku") {
      document.querySelector("html").setAttribute("dir", "rtl");
    } else {
      document.querySelector("html").removeAttribute("dir");
    }
  };
  useEffect(() => {
    changestyle();
  }, []);
  return (
    <footer className={footerRtlStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="content">
              <div className="logo">
                <a href="index.html">
                  <img src={helpers.mainLogo(true)} alt="logo" />
                </a>
              </div>
              <p>{window.t("footerDesc")}</p>
              <ul className="social">
                <li>
                  <a
                    href="https://www.facebook.com/techeye.org/"
                    target="_blank"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/techeyeorg" target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/techeyeorg/?hl=en"
                    target="_blank"
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                </li>
                <div className="row d-flex justify-content-center align-items-center mt-5">
                  <div className="col-lg-4 d-flex justify-content-center align-items-center">
                    {Object.keys(lngs).map((lng) => (
                      <button
                        key={lng}
                        className="mx-2"
                        style={{
                          backgroundColor:
                            i18n.resolvedLanguage === lng ? "#00689b" : "white",
                        }}
                        onClick={() => {
                          i18n.changeLanguage(lng);
                          changeStyleOnClick();
                        }}
                      >
                        <a
                          className="nav-link"
                          style={{
                            color:
                              i18n.resolvedLanguage === lng ? "white" : "black",
                          }}
                        >
                          {lngs[lng].nativeName}
                        </a>
                      </button>
                    ))}
                  </div>
                </div>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6">
            <div className="content ml-15">
              <h3>{window.t("title1")}</h3>
              <ul className="footer-list">
                <li>
                  <p>Visual Design</p>
                </li>
                <li>
                  <p> Development</p>
                </li>
                <li>
                  <p>QA & Testing</p>
                </li>
                <li>
                  <p>IT Management</p>
                </li>
                <li>
                  <p>Cyber Security</p>
                </li>
                <li>
                  <p>Wireless Connection</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="content contacts">
              <h3 className="ml-40">{window.t("title2")}</h3>
              <ul className="footer-list foot-social">
                <li>
                  <a href="tel:+964 750 451 5480">
                    <i className="bx bx-phone"></i> +964 750 451 5480
                  </a>
                </li>
                <li>
                  <a href="mailto:hello@tech-eye.com">
                    <i className="bx bxs-envelope"></i> info@tech-eye.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="content">
              <h3>{window.t("title3")}</h3>
              <ul className="footer-list">
                <li>
                  <p>
                    <i className="bx bxs-map"></i>&nbsp;&nbsp;{" "}
                    {window.t("address")}
                  </p>
                </li>
                <li>
                  <p>{window.t("timeOfWorks")}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="copy-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ul className="menu">
                <li>
                  &nbsp;<Link to={"/"}>{window.t("home")}</Link>&nbsp;
                </li>
                <li>
                  &nbsp;<Link to={"/about"}>{window.t("about")}</Link>&nbsp;
                </li>
              </ul>
            </div>
            <div className="col-lg-6 text-right">
              <p className="right">
                {window.t("cr")} &nbsp;
                <a href="https://tech-eye.com" target="_blank">
                  {window.t("team")}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
