import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "./Language.css";

const lngs = {
  en: { nativeName: "English" },
  ku: { nativeName: "كوردی" },
  ar: { nativeName: "العربية" },
};

export default function Language() {
  const { i18n } = useTranslation();
  var navStyle = classNames("navbar-nav", {
    "ms-auto": i18n.resolvedLanguage == "en",
    "me-auto": i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  });
  var dropDownMenuStyle = classNames("dropdown-menu", {
    "text-start": i18n.resolvedLanguage == "en",
    "text-end": i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
    "dropdown-menu-rtl":
      i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  });

  const changestyle = () => {
    if (i18n.resolvedLanguage == "ar" || i18n.resolvedLanguage == "ku") {
      document.querySelector("html").setAttribute("dir", "rtl");
      // const swippers = document.querySelectorAll(".myProductSwiper");
      // for (let i = 0; i < swippers.length; i++) {
      //   swippers[i].classList.add("swiper-rtl");
      // }
    } else {
      document.querySelector("html").removeAttribute("dir");
      // const swippers = document.querySelectorAll(".myProductSwiper");
      // for (let i = 0; i < swippers.length; i++) {
      //   swippers[i].classList.remove("swiper-rtl");
      // }
    }
  };
  const changeStyleOnClick = () => {
    window.location.reload();
  };
  useEffect(() => {
    changestyle();
  }, []);

  return (
    <ul className={navStyle}>
      <li className="nav-item">
        <a href="#" className="nav-link dropdown-toggle">
          {i18n.resolvedLanguage == "ar"
            ? "العربية"
            : i18n.resolvedLanguage == "ku"
            ? "كوردی"
            : "English"}
        </a>
        <ul className={dropDownMenuStyle}>
          {Object.keys(lngs).map((lng) => (
            <li
              key={lng}
              className="nav-item"
              style={{
                backgroundColor:
                  i18n.resolvedLanguage === lng ? "#00689b" : "white",
              }}
              type="submit"
              onClick={() => {
                i18n.changeLanguage(lng);
                changeStyleOnClick();
              }}
            >
              <a
                className="nav-link"
                style={{
                  color: i18n.resolvedLanguage === lng ? "white" : "black",
                }}
              >
                {lngs[lng].nativeName}
              </a>
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
}
