import React, { useEffect, useState, useRef } from "react";
import { clients } from "../../helpers/collectionNames";
import app from "./../../firebase.js";
import { useTranslation } from "react-i18next";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./css/client.css";

// import required modules
import { Autoplay, Pagination } from "swiper";

function Client() {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [allClients, setAllClients] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, clients));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllClients(tempData);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="client-area ptb-100 bg-color" id="our-clients">
      <div className="">
        <div className="section-title">
          <span>{window.t("clientTitle")}</span>
          <h2>{window.t("clientTitleBig")}</h2>
          <p>{window.t("clientDesc")}</p>
        </div>

        {allClients.length == 0 ? null : (
          <Swiper
            slidesPerView={5}
            dir={i18n.language == "en" ? "ltr" : "rtl"}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay, Pagination]}
            className="myProductSwiper pb-5"
          >
            {allClients &&
              allClients.map((e, index) => (
                <SwiperSlide key={index}>
                  <div className="home-team-slider">
                    <div className="single-team">
                      <div className="team-img">
                        <img src={e.image} alt="team" />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}

        {/* <div className="client-wrap">
          <div>
            <img src="assets/images/client/1.jpg" alt="img" />
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Client;
