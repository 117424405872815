import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAM4oZhwndXinLZFXwX4cpoODtMfp8lcIc",
  authDomain: "techeye-f889f.firebaseapp.com",
  projectId: "techeye-f889f",
  storageBucket: "techeye-f889f.appspot.com",
  messagingSenderId: "1020783229605",
  appId: "1:1020783229605:web:eda170b3f100fb8cd46b4c",
  measurementId: "G-59NLWBY5CP"
};

const app = initializeApp(firebaseConfig);
export const appAuth = getAuth(app);

export default app;