import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Animation from "../Shared/Animation";
import UpdatedTechnology from "./../../Assets/Images/UpdatedTechnology.gif";

function Technology() {
  return (
    <section className="technology-area ptb-100 bg-color">
      <div className="container">
        <div className="row align-items-center choose-c">
          <div className="col-lg-6">
            <Animation animate="fade-up" delay={200}>
              <div className="choose-img">
                <img src={UpdatedTechnology} alt="choose" loop="infinite" />
                <div className="technology-video">
                  <a
                    href="https://www.youtube.com/watch?v=TdSA7gkVYU0"
                    className="video-btn popup-youtube"
                  >
                    <i className="bx bx-play"></i>
                  </a>
                </div>
              </div>
            </Animation>
          </div>

          <div className="col-lg-6">
            <Animation animate="fade-down" delay={400}>
              <div className="technology-content">
                <h2>{window.t("technologyTitle")}</h2>
                <p>{window.t("technologyDesc1")}</p>
                <p>{window.t("technologyDesc2")}</p>

                <Link to={"/about"} href="contact.html" className="box-btn">
                  {window.t("aboutUs")}
                </Link>
              </div>
            </Animation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Technology;
