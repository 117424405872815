import React, { useEffect, useState, useRef } from "react";
import helpers from "./../Constants/helpers";

function Solutions() {
  return (
    <div className="solutions">
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Solutions</h2>
          </div>
        </div>
        <div className="page-shape">
          <div className="shape1">
            <img src={helpers.getShape(1)} alt="shape" />
          </div>
          <div className="shape3">
            <img src={helpers.getShape(3)} alt="shape" />
          </div>
          <div className="shape4">
            <img src={helpers.getShape(4)} alt="shape" />
          </div>
          <div className="shape5">
            <img src={helpers.getShape(5)} alt="shape" />
          </div>
          <div className="shape6">
            <img src={helpers.getShape(6)} alt="shape" />
          </div>
        </div>
      </div>
      <section className="home-service-area ptb-100">
        <div className="container">
          <div className="section-title">
            <span>Smart Services</span>
            <h2>Paso Provide All Kind of Tech Solutions</h2>
            <p>
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s1.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3>Visual Design</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s2.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3>Development</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s3.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3>QA Testing</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s4.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3>IT Management</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s5.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3> Cyber Security</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-service">
                <div className="service-img">
                  <img src="assets/images/service/s6.png" alt="service" />
                </div>

                <div className="service-content">
                  <h3> Wireless Connectivity</h3>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Atque vel sit maxime assumenda. maiores, magnam
                  </p>

                  <a href="solutions-details.html" className="line-bnt">
                    Read More
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="page-navigation-area">
                <nav aria-label="Page navigation example text-center">
                  <ul className="pagination">
                    <li className="page-item">
                      <a className="page-link page-links" href="#">
                        <i className="bx bx-chevrons-left"></i>
                      </a>
                    </li>
                    <li className="page-item active">
                      <a className="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="bx bx-chevrons-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Solutions;
