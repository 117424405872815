import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { contactus } from "./../../../helpers/collectionNames";
import app from "../../../firebase";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";

export default function ListContactUs() {
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [perPage, setPerPage] = useState(3);
  const [allContactUs, setAllContactUs] = useState([]);

  const getData = async () => {
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, contactus));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllContactUs(tempData);
  };

  const deleteContactUs = async (id) => {
    setIsLoading(true);
    const db = getFirestore(app);
    const docRef = doc(db, contactus, id);
    await deleteDoc(docRef)
      .then(() => {
        setIsLoading(false);
        getData();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const nextPagination = () => {
    setStart(start + perPage);
    setEnd(end + perPage);
  };

  const prevPagination = () => {
    setStart(start - perPage);
    setEnd(end - perPage);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container my-5 py-5">
      <div>
        <h1>People Messages</h1>
      </div>
      <br />
      <div className="row">
        <div className="table-responsive">
          <table className="table text-center">
            <thead className="table-dark">
              <tr>
                <th scope="col">{window.t("no")}</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">{window.t("phoneNumber")}</th>
                <th scope="col">Subject</th>
                <th scope="col">Message</th>
                <th scope="col">{window.t("delete")}</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className="text-center">
                <tr>
                  <td>{window.t("loading")}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {allContactUs &&
                  allContactUs.slice(start, end).map((e) => {
                    return (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>{e.name}</td>
                        <td>{e.email}</td>
                        <td>{e.phone}</td>
                        <td>{e.subject}</td>
                        <td>{e.message}</td>
                        <td>
                          <button
                            onClick={() => {
                              deleteContactUs(e.id);
                            }}
                            className="btn btn-danger radius"
                          >
                            {window.t("delete")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-6 d-flex justify-content-center text-center col-6">
          <button
            className="btn btn-danger"
            disabled={start == 0}
            onClick={prevPagination}
          >
            Prev
          </button>
        </div>
        <div className="col-lg-6 d-flex justify-content-center text-center col-6">
          <button
            className="btn btn-danger"
            disabled={end >= allContactUs.length}
            onClick={nextPagination}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
