import React, { useEffect, useState, useRef } from "react";
import CompanyInfo from "./../Components/About/CompanyInfo";
import TechnologyArea from "./../Components/About/TechnologyArea";
import OurFeatures from "./../Components/About/OurFeatures";
import helpers from "./../Constants/helpers";

function About() {
  return (
    <div>
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>{window.t("about")}</h2>
          </div>
        </div>
        <div className="page-shape">
          <div className="shape1">
            <img src={helpers.getShape(1)} alt="shape" />
          </div>
          <div className="shape3">
            <img src={helpers.getShape(3)} alt="shape" />
          </div>
          <div className="shape4">
            <img src={helpers.getShape(4)} alt="shape" />
          </div>
          <div className="shape5">
            <img src={helpers.getShape(5)} alt="shape" />
          </div>
          <div className="shape6">
            <img src={helpers.getShape(6)} alt="shape" />
          </div>
        </div>
      </div>
      <CompanyInfo />
      <TechnologyArea />
      <OurFeatures />
    </div>
  );
}

export default About;
