import React, { useEffect, useState } from "react";
import Animation from "../Shared/Animation";
import Vision1 from "../../Assets/Images/Home/vision-1.png";
import Vision2 from "../../Assets/Images/Home/vision-2.png";
import Vision3 from "../../Assets/Images/Home/vision-3.png";
import app from "../../firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import helpers from "../../Constants/helpers";
import { InfoArea as InfoAreaCollection } from "../../helpers/collectionNames";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import "../Shared/Language.css";

function InfoArea() {
  const { i18n } = useTranslation();
  var closeBtnStyle = classNames("btn-close", {
    closeButtonRTL:
      i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [allInfoAreas, setAllInfoAreas] = useState([]);

  const getData = async () => {
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, InfoAreaCollection));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllInfoAreas(tempData);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getIMage = (index) => {
    if (index == 0) {
      return Vision2;
    } else {
      return index == 1 ? Vision3 : Vision1;
    }
  };

  return (
    <div className="info-area pt-100 pb-70">
      {isLoading ? (
        <p className="text-center">{window.t("loading")}</p>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <Animation animate="fade-down">
                <Link to={"/about"} className="btn btn-primary">
                  <div className="single-info">
                    <div className="info-img">
                      <img src={Vision1} alt="info" />
                    </div>
                    <div className="content">
                      <h3>
                        <i className="flaticon-info"></i>
                        &nbsp;{window.t("aboutUs")}&nbsp;
                      </h3>
                      <div className="arrow">
                        <i className="flaticon-next-1"></i>
                      </div>
                    </div>
                  </div>
                </Link>
              </Animation>
            </div>

            {allInfoAreas &&
              allInfoAreas.map((e, index) => {
                return (
                  <div
                    className="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0"
                    key={index}
                  >
                    <Animation animate={helpers.getAnimationStyle(index)}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ backgroundColor: "transparent" }}
                        data-bs-toggle="modal"
                        data-bs-target={`#techeye${e.id}`}
                      >
                        <div className="single-info">
                          <div className="info-img">
                            <img src={getIMage(index)} alt="info" />
                          </div>
                          <div className="content">
                            <h3>
                              <i className="flaticon-support"></i>
                              &nbsp;{helpers.getTitleByLang(e)}&nbsp;
                            </h3>
                            <div className="arrow">
                              <i className="flaticon-next-1"></i>
                            </div>
                          </div>
                        </div>
                      </button>
                    </Animation>
                    <div
                      className="modal fade"
                      id={`techeye${e.id}`}
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              &nbsp;{helpers.getTitleByLang(e)}&nbsp;
                            </h5>
                            <button
                              type="button"
                              className={closeBtnStyle}
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            {helpers.getDescrByLang(e)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
}

export default InfoArea;
