import React, { useEffect, useState, useRef } from "react";
import Area from "../Components/Home/Area";
import Blogs from "../Components/Home/Blogs";
import CaseStudio from "../Components/Home/CaseStudio";
import Client from "../Components/Home/Client";
import ContactUs from "../Components/Home/ContactUs";
import InfoArea from "../Components/Home/InfoArea";
import Process from "../Components/Home/Process";
import Service from "../Components/Home/Service";
import Team from "../Components/Home/Team";
import Technology from "../Components/Home/Technology";
import TopSlider from "./../Components/Home/TopSlider";

function Home() {
  return (
    <div className="home">
      <TopSlider />
      <InfoArea />
      <Service />
      <Process />
      <Area />
      <Technology />
      {/* <CaseStudio /> */}
      <Team />
      <Client />
      {/* <Blogs /> */}
      <ContactUs />
    </div>
  );
}

export default Home;
