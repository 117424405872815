import React, { useEffect, useState, useRef } from "react";
import Typed from "react-typed";
import { useTranslation } from "react-i18next";
import { jumbotron } from "../../helpers/collectionNames";
import app from "../../firebase";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";
import helpers from "../../Constants/helpers";

function TopSlider() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [allJumbotrons, setAllJumbotrons] = useState([]);
  const getData = async () => {
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, jumbotron));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllJumbotrons(tempData);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="slider-area-2">
      <div className="home-slider">
        <div className="owl-stage-outer owl-height" style={{ height: "100vh" }}>
          <div className="single-slider single-slider-bg-1">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-12 text-center">
                      {isLoading ? (
                        <div className="slider-content one">
                          {window.t("loading")}
                        </div>
                      ) : (
                        <div className="slider-content one">
                          <h1>
                            <Typed
                              strings={allJumbotrons.map((e) => {
                                return helpers.getTitleByLang(e);
                              })}
                              typeSpeed={40}
                              backSpeed={50}
                              loop
                            />
                          </h1>
                          <p>
                            <Typed
                              strings={allJumbotrons.map((e) => {
                                return helpers.getDescrByLang(e);
                              })}
                              typeSpeed={30}
                              backSpeed={10}
                              loop
                            />
                          </p>
                          <div className="slider-btn text-center text-light">
                            <i className="fa fa-arrow-down fa-2xl"></i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopSlider;
