import React from "react";
import { Link } from "react-router-dom";
import Animation from "./Animation";
import Language from "./Language";
// import { useTranslation } from "react-i18next";

function Navbar() {
  // const { t } = useTranslation();

  return (
    <div className="navbar-area">
      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md">
            <div
              className="collapse navbar-collapse mean-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav text-center">
                <Animation animate="fade-left" delay={200}>
                  <li className="nav-item">
                    <Link to={"/"} className="nav-link">
                      {window.t("home")}
                    </Link>
                  </li>
                </Animation>
                <Animation animate="fade-left" delay={400}>
                  <li className="nav-item">
                    <a href="#our-solutions" className="nav-link">
                      {window.t("solutions")}
                    </a>
                  </li>
                </Animation>
                <Animation animate="fade-left" delay={600}>
                  <li className="nav-item">
                    <a href="#our-products" className="nav-link">
                      {window.t("ourProducts")}
                    </a>
                  </li>
                </Animation>
                <Animation animate="fade-left" delay={800}>
                  <li className="nav-item">
                    <a href="#our-clients" className="nav-link">
                      {window.t("clientTitle")}
                    </a>
                  </li>
                </Animation>
                <Animation animate="fade-left" delay={1000}>
                  <li className="nav-item">
                    <Link to={"/about"} className="nav-link">
                      {window.t("about")}
                    </Link>
                  </li>
                </Animation>
                <Animation animate="fade-left" delay={1200}>
                  <li className="nav-item">
                    <a href="#contact-us" className="nav-link">
                      {window.t("contactUsTitle")}
                    </a>
                  </li>
                </Animation>
                {/* <li className="nav-item">
                  <Link to={"/team"} className="nav-link">
                    Team
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link dropdown-toggle">
                    Solutions
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <Link to={"/solutions"} className="nav-link">
                        Solutions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/solution-details/10"} className="nav-link">
                        Solutions Details
                      </Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
              <Language />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
