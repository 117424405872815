import React, { useEffect, useState, useRef } from "react";
import CompanyInfoImage from "./../../Assets/Images/About/company-info.png";

function CompanyInfo() {
  return (
    <section className="home-company-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="company-content">
              <div className="company-tittle">
                <span>{window.t("ourCompanyTitle")}</span>
                <h2>{window.t("ourCompanyTitleBig")}</h2>

                <p>{window.t("ourCompanyDesc1")}</p>

                <p>{window.t("ourCompanyDesc2")}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="company-img">
              <img src={CompanyInfoImage} alt="company" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompanyInfo;
