import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./../Translation/en";
import { ku } from "./../Translation/ku";
import { ar } from "./../Translation/ar";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      ku: {
        translation: ku,
      },
      ar: {
        translation: ar,
      },
    },
  });

export default i18n;
