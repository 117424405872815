import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Admin from "../Components/Admin/Admin";
import Dashboard from "../Components/Admin/Dashboard";

//our clients
import ListClients from "./../Components/Admin/Clients/ListClients";
import AddClient from "./../Components/Admin/Clients/AddClient";
import EditClient from "./../Components/Admin/Clients/EditClient";

//products
import ListProducts from "./../Components/Admin/Products/ListProducts";
import AddProduct from "./../Components/Admin/Products/AddProduct";
import EditProduct from "./../Components/Admin/Products/EditProduct";

//jumbotrons
import ListJumbotrons from "./../Components/Admin/Jumbotron/ListJumbotrons";
import AddJumbotron from "./../Components/Admin/Jumbotron/AddJumbotron";
import EditJumbotron from "./../Components/Admin/Jumbotron/EditJumbotron";

//infoArea
import ListInfoAreas from "./../Components/Admin/InfoArea/ListInfoAreas";
import AddInfoArea from "./../Components/Admin/InfoArea/AddInfoArea";
import EditInfoArea from "./../Components/Admin/InfoArea/EditInfoArea";

//services
import ListServices from "./../Components/Admin/Services/ListServices";
import AddService from "./../Components/Admin/Services/AddService";
import EditService from "./../Components/Admin/Services/EditService";

//contact us
import ListContactUs from "./../Components/Admin/ContactUs/ListContactUs";

import Layout from "../Components/Layout";
import About from "../Pages/About";
import Solutions from "../Pages/Solutions";
import Team from "../Pages/Team";
import Home from "./../Pages/Home";
import Page404 from "./../Pages/Page404";
import SolutionDetails from "./../Pages/SolutionDetails";
import { appAuth } from "./../firebase";
import PreLoader from "./../Components/Shared/PreLoader";

const Routing = () => {
  const { i18n } = useTranslation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const changestyle = () => {
    if (i18n.resolvedLanguage == "ar" || i18n.resolvedLanguage == "ku") {
      document.querySelector("html").setAttribute("dir", "rtl");
    } else {
      document.querySelector("html").removeAttribute("dir");
    }
  };
  useEffect(() => {
    changestyle();
    appAuth.onAuthStateChanged((user) => {
      setIsLoading(false);
      if (user != null) {
        setIsLoggedIn(true);
      }
    });
  }, []);
  return (
    <div>
      {isLoading ? (
        <Routes>
          <Route path="/" element={<PreLoader />} />
        </Routes>
      ) : (
        <Routes>
          {/* admin and dashboard */}
          <Route
            path="/admin"
            element={isLoggedIn ? <Navigate to="/dashboard" /> : <Admin />}
          />
          <Route
            path="/dashboard"
            element={isLoggedIn ? <Dashboard /> : <Navigate to="/admin" />}
          />

          {/* our clients */}
          <Route
            path="/admin/list-clients"
            element={isLoggedIn ? <ListClients /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/add-client"
            element={isLoggedIn ? <AddClient /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/edit-client/:id"
            element={isLoggedIn ? <EditClient /> : <Navigate to="/" />}
          />

          {/* our products */}
          <Route
            path="/admin/list-products"
            element={isLoggedIn ? <ListProducts /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/add-product"
            element={isLoggedIn ? <AddProduct /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/edit-product/:id"
            element={isLoggedIn ? <EditProduct /> : <Navigate to="/" />}
          />

          {/* jumbotrons */}
          <Route
            path="/admin/list-jumbotrons"
            element={isLoggedIn ? <ListJumbotrons /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/add-jumbotron"
            element={isLoggedIn ? <AddJumbotron /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/edit-jumbotron/:id"
            element={isLoggedIn ? <EditJumbotron /> : <Navigate to="/" />}
          />

          {/* InfoArea */}
          <Route
            path="/admin/list-info-areas"
            element={isLoggedIn ? <ListInfoAreas /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/add-info-area"
            element={isLoggedIn ? <AddInfoArea /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/edit-info-area/:id"
            element={isLoggedIn ? <EditInfoArea /> : <Navigate to="/" />}
          />

          {/* Services */}
          <Route
            path="/admin/list-services"
            element={isLoggedIn ? <ListServices /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/add-service"
            element={isLoggedIn ? <AddService /> : <Navigate to="/" />}
          />
          <Route
            path="/admin/edit-service/:id"
            element={isLoggedIn ? <EditService /> : <Navigate to="/" />}
          />

          {/* contact us */}
          <Route
            path="/admin/list-contactus"
            element={isLoggedIn ? <ListContactUs /> : <Navigate to="/" />}
          />

          {/* home page routes */}
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/team" element={<Team />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/solution-details/:id" element={<SolutionDetails />} />
          </Route>

          {/* 404 page routes */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      )}
    </div>
  );
};

export default Routing;
