import React from "react";
import { PropTypes } from "prop-types";

function Animation({
  children,
  animate,
  offset = 0,
  delay = 10,
  duration = 0.5,
  easing = "ease-in-out",
  mirror = false,
  animateOnce = false,
  anchorPlacement = "top-center",
}) {
  return (
    <div
      data-aos={animate}
      data-aos-offset={offset}
      data-aos-delay={delay}
      data-aos-duration={duration}
      data-aos-easing={easing}
      data-aos-mirror={mirror}
      data-aos-once={animateOnce}
      data-aos-anchor-placement={anchorPlacement}
    >
      {children}
    </div>
  );
}

Animation.propTypes = {
  children: PropTypes.node.isRequired,
  animate: PropTypes.string.isRequired,
  offset: PropTypes.number,
  delay: PropTypes.number,
  duration: PropTypes.number,
  easing: PropTypes.string,
  mirror: PropTypes.bool,
  animateOnce: PropTypes.bool,
  anchorPlacement: PropTypes.string,
};

export default Animation;
