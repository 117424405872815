import React, { useEffect, useState, useRef } from "react";
import Animation from "../Shared/Animation";
import { services } from "../../helpers/collectionNames";

import app from "../../firebase";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
import helpers from "./../../Constants/helpers";
function Service() {
  const [isLoading, setIsLoading] = useState(true);
  const [allServices, setAllServices] = useState([]);

  const getData = async () => {
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, services));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllServices(tempData);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div id="our-solutions">
      <section className="home-service-area pb-70">
        {isLoading ? (
          <p className="text-center">{window.t("loading")}</p>
        ) : (
          <div className="container">
            <div className="section-title">
              <span>{window.t("servicesTitle")}</span>
              <h2>{window.t("servicesTitleBig")}</h2>
              <p>{window.t("servicesDesc")}</p>
            </div>

            <div className="row">
              {allServices &&
                allServices.map((e, index) => {
                  return (
                    <div className="col-lg-4 col-sm-6" key={index}>
                      <Animation
                        animate={helpers.getAnimationStyle(index)}
                        delay={10}
                      >
                        <div
                          className="single-service"
                          style={{ height: "270px" }}
                        >
                          <div className="service-img">
                            <img src={e.image} alt="service" />
                          </div>

                          <div className="service-content">
                            <h3>{helpers.getTitleByLang(e)}</h3>
                            <p>{helpers.getDescrByLang(e)}</p>
                          </div>
                        </div>
                      </Animation>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </section>
    </div>

  );
}

export default Service;
