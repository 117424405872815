import React, { useEffect, useState, useRef } from "react";
import Features from "./../../Assets/Images/About/features.mp4";

function OurFeatures() {
  return (
    <section className="feature-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="contnet">
              <div className="feature-tittle">
                <span>{window.t("featuresTitle")}</span>
                <h2>{window.t("featuresTitleBig")}</h2>
                <p>{window.t("featuresDesc1")}</p>
              </div>

              <ul>
                <li>
                  <i className="flaticon-correct"></i>
                  {window.t("featuresDesc2")}
                </li>
                <li>
                  <i className="flaticon-correct"></i>
                  {window.t("featuresDesc3")}
                </li>
                <li>
                  <i className="flaticon-correct"></i>
                  {window.t("featuresDesc4")}
                </li>
                <li>
                  <i className="flaticon-correct"></i>
                  {window.t("featuresDesc5")}
                </li>
                <li>
                  <i className="flaticon-correct"></i>
                  {window.t("featuresDesc6")}
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="feature-img">
              <video autoPlay muted loop>
                <source src={Features} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurFeatures;
