import React, { useEffect, useState, useRef } from "react";
import Animation from "../Shared/Animation";

function Area() {
  return (
    <section className="home2-choose-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span>{window.t("araeTitle")}</span>
          <h2>{window.t("araeTitleBig")}</h2>
          <p>{window.t("araeDesc")}</p>
        </div>

        <div className="row">
          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-up" delay={100}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-friends"></i>
                </div>
                <h3>{window.t("areaChildTitle1")}</h3>
              </div>
            </Animation>
          </div>

          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-down" delay={200}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-award"></i>
                </div>
                <h3>{window.t("areaChildTitle2")}</h3>
              </div>
            </Animation>
          </div>

          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-up" delay={300}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-chip"></i>
                </div>
                <h3>{window.t("areaChildTitle3")}</h3>
              </div>
            </Animation>
          </div>

          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-down" delay={400}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-customer-service"></i>
                </div>
                <h3>{window.t("areaChildTitle4")}</h3>
              </div>
            </Animation>
          </div>

          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-up" delay={500}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-like"></i>
                </div>
                <h3>{window.t("areaChildTitle5")}</h3>
              </div>
            </Animation>
          </div>

          <div className="col-lg-2 col-sm-4">
            <Animation animate="fade-down" delay={600}>
              <div className="single-choose">
                <div className="icon">
                  <i className="flaticon-coin"></i>
                </div>
                <h3>{window.t("areaChildTitle6")}</h3>
              </div>
            </Animation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Area;
