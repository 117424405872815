import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <div className="container my-5 py-5 text-center">
      <div className="one my-4">
        <h1>{window.t("ourClient")}</h1>
        <hr />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4">
            <h3>{window.t("add")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/add-client" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("add")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3>{window.t("list")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/list-clients" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("list")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="one my-4">
          <h1>{window.t("ourProducts")}</h1>
          <hr />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-4">
              <h3>{window.t("add")}</h3>
              <div className="card text-center">
                <div className="card-body">
                  <Link
                    to={{ pathname: "/admin/add-product" }}
                    className="btn btn-primary w-100 radius"
                  >
                    {window.t("add")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h3>{window.t("list")}</h3>
              <div className="card text-center">
                <div className="card-body">
                  <Link
                    to={{ pathname: "/admin/list-products" }}
                    className="btn btn-primary w-100 radius"
                  >
                    {window.t("list")}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="one my-4">
        <h1>{window.t("jumbotron")}</h1>
        <hr />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4">
            <h3>{window.t("add")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/add-jumbotron" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("add")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3>{window.t("list")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/list-jumbotrons" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("list")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="one my-4">
        <h1>{window.t("ourServices")}</h1>
        <hr />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4">
            <h3>{window.t("add")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/add-service" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("add")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3>{window.t("list")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/list-services" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("list")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="one my-4">
        <h1>{window.t("infoArea")}</h1>
        <hr />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4">
            <h3>{window.t("add")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/add-info-area" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("add")}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <h3>{window.t("list")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/list-info-areas" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("list")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="one my-4">
        <h1>{window.t("contactUs")}</h1>
        <hr />
        <div className="row d-flex justify-content-center">
          <div className="col-lg-4">
            <h3>{window.t("list")}</h3>
            <div className="card text-center">
              <div className="card-body">
                <Link
                  to={{ pathname: "/admin/list-contactus" }}
                  className="btn btn-primary w-100 radius"
                >
                  {window.t("list")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
