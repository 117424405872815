import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { products } from "./../../../helpers/collectionNames";
import app from "../../../firebase";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";

export default function ListProducts() {
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [perPage, setPerPage] = useState(3);
  const [allProducts, setAllProducts] = useState([]);

  const getData = async () => {
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, products));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllProducts(tempData);
  };

  const deleteClient = async (id) => {
    setIsLoading(true);
    const db = getFirestore(app);
    const docRef = doc(db, products, id);
    await deleteDoc(docRef)
      .then(() => {
        setIsLoading(false);
        getData();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const nextPagination = () => {
    setStart(start + perPage);
    setEnd(end + perPage);
  };

  const prevPagination = () => {
    setStart(start - perPage);
    setEnd(end - perPage);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="container my-5 py-5">
      <div className="d-flex justify-content-between align-items-center">
        <h1>{window.t("ourProducts")}</h1>
        <Link
          to={{ pathname: "/admin/add-product" }}
          className="btn btn-primary radius"
        >
          Add Product
        </Link>
      </div>
      <br />
      <div className="row">
        <div className="table-responsive">
          <table className="table text-center">
            <thead className="table-dark">
              <tr>
                <th scope="col">{window.t("no")}</th>
                <th scope="col">{window.t("titleKu")}</th>
                <th scope="col">{window.t("titleAr")}</th>
                <th scope="col">{window.t("titleEn")}</th>
                <th scope="col">{window.t("attachment")}</th>
                <th scope="col">{window.t("edit")}</th>
                <th scope="col">{window.t("delete")}</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody className="text-center">{window.t("loading")}</tbody>
            ) : (
              <tbody>
                {allProducts &&
                  allProducts.slice(start, end).map((e) => {
                    return (
                      <tr key={e.id}>
                        <th scope="row">{e.id}</th>
                        <td>{e.titleKu}</td>
                        <td>{e.titleAr}</td>
                        <td>{e.titleEn}</td>
                        <td>
                          <img src={e.image} width="150" alt={e.image} />
                        </td>
                        <td>
                          <Link
                            to={`/admin/edit-product/${e.id}`}
                            className="btn btn-warning radius"
                          >
                            Edit
                          </Link>
                        </td>
                        <td>
                          <button
                            onClick={() => {
                              deleteClient(e.id);
                            }}
                            className="btn btn-danger radius"
                          >
                            {window.t("delete")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-lg-6 d-flex justify-content-center text-center col-6">
          <button
            className="btn btn-danger"
            disabled={start == 0}
            onClick={prevPagination}
          >
            Prev
          </button>
        </div>
        <div className="col-lg-6 d-flex justify-content-center text-center col-6">
          <button
            className="btn btn-danger"
            disabled={end >= allProducts.length}
            onClick={nextPagination}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
