export const ar = {
  home: "الصفحة الرئيسية",
  about: "حول",
  // info area
  ourMission: "رسالتنا",
  ourVision: "رؤيتنا",
  aboutUs: "من نحن",

  //services
  servicesTitle: "الخدمات الذكية",
  solutions: "حلول",
  servicesTitleBig: "تقدم Techeye جميع أنواع الحلول التقنية",
  servicesDesc:
    "نحن نساعد الشركات المذهلة على بناء منتجات رائعة ، ودعم جميع العملاء الذين يثقون بنا ويسيرون معنا على هذا الطريق.",

  // process
  processTitle: "عملية العمل",
  processTitleBig: "نحن مشهورون بسبب طريقتنا في العمل",
  processDesc:
    "قد تندهش من ما يمكننا القيام به. نحن نقدم العديد من الخدمات التي يمكنك أن تجدها أدناه.",
  processChildTitle1: "منتج البحث",
  processChildTitle2: "اختبار المستخدم",
  processChildTitle3: "تطوير",
  processChildTitle4: "تسليم المنتج",
  processChildDesc1:
    "قد يكون من الصعب تحديد حجم السوق ولكن مع بعض التخمينات المستنيرة يمكنك على الأرجح الحصول على فكرة جيدة. على سبيل المثال ، المنتج الذي يلبي احتياجات النساء الحوامل اللواتي تتراوح أعمارهن بين 25 و 40 عامًا ربما يكون لديه سوق كافٍ.",
  processChildDesc2:
    "اختبار المستخدم هو العملية التي يتم من خلالها اختبار واجهة ووظائف موقع ويب أو تطبيق أو منتج أو خدمة بواسطة مستخدمين حقيقيين يؤدون مهام محددة في ظروف واقعية.",
  processChildDesc3:
    "يشير تطوير البرامج إلى مجموعة من أنشطة علوم الكمبيوتر المخصصة لعملية إنشاء البرامج وتصميمها ونشرها ودعمها.",
  processChildDesc4:
    "بعد الانتهاء من المنتج كما هو مقرر في إنهاء العقد ، سيقوم فريقنا بتسليم المنتج إلى العميل.",

  //arae
  araeTitle: "اختر Techeye",
  araeTitleBig: "لقد حققنا ثقة الشعوب من خلال خدمتنا الرائعة",
  areaChildTitle1: "فهم",
  areaChildTitle2: "أفضل جودة",
  areaChildTitle3: "تكنولوجيا",
  areaChildTitle4: "دعم 24/7",
  areaChildTitle5: "فريق الخبراء",
  areaChildTitle6: "السعر الموجه",

  //Technology
  technologyTitle: "نحن خبراء في مجالنا",
  technologyDesc1:
    "تحت شعار فريد من نوعه ، ولكن ذو دلالة فائقة ، وهو 'التكنولوجيا هي عالمنا' ، بدأت Tech Eye كأحد المشاريع المحلية الرائدة في إقليم كردستان العراق لتلبية وتوفير الاحتياجات والخدمات التكنولوجية.",
  technologyDesc2:
    "ما يجعلنا شركة متميزة هي شركة متطورة ومتطورة في مجال تقنية المعلومات. نحن نقدم خدمات مثل تصميم الويب ، والشبكات القائمة على البيانات ، وتطوير الويب ، والخادم ، ومضيف الويب ، والتسويق الرقمي ، على سبيل المثال لا الحصر.",

  //Our Products
  productTitle: "منتجاتنا",
  productTitleBig: "أحدث منتجاتنا",
  productDesc:
    "نحاول دائمًا تنفيذ الأفكار الإبداعية على أعلى مستوى. يمكنك رؤيتها من خلال النظر في محفظتنا.",

  //Clients
  clientTitle: "العملاء",
  clientTitleBig: "من هم عملاؤنا",
  clientDesc:
    "هؤلاء هم الأشخاص الذين يثقون بنا وسيوظفوننا كلما كان لديهم مشروع يحتاج إلى العمل عليه.",

  //Contact Us
  contactUsTitle: "اتصل بنا",
  contactUsTitleBig: "أخبرنا عن فكرة مشروعك!",
  contactUsDesc:
    "هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى الحقيقي لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي ينويها. الهدف من استخدام Lorem Ipsum هو أنه يحتوي على المزيد.",
  contactUsChildInput1: "اسمك",
  contactUsChildInput2: "بريدك الالكتروني",
  contactUsChildInput3: "هاتفك",
  contactUsChildInput4: "موضوعات",
  contactUsChildInput5: "رسالتك",
  contactUsChildInput6: "رسالتك",
  contactUsSending: "جاري الارسال ...",

  //Technology
  ourCompanyTitle: "شركتنا",
  ourCompanyTitleBig: "لماذا تختارنا",
  ourCompanyDesc1:
    "ولا يزال التفاني في خدمة العملاء شاغلنا الرئيسي. نحن نخطط للتسليم في الموعد المحدد ، في الميزانيات ووفقا لمعاييرك. نجاحنا مهيأ بالشفافية والمهنية. وتركز المبادئ على ضمان استجابة الفريق دائما لاحتياجات عملائنا.",
  ourCompanyDesc2:
    "نحن نساعد الشركات المذهلة على بناء منتجات رائعة ، ودعم جميع العملاء الذين يثقون بنا ويسيرون معنا على هذا الطريق.",

  //Features
  featuresTitle: "الميزات",
  featuresTitleBig: "لدينا أيضا بعض الميزات التي توفرها TechEye",
  featuresDesc1: "نحن نقدم هذه الخدمات :",
  featuresDesc2: "دورة التدريب الحر",
  featuresDesc3: "الاستشارات التكنولوجية",
  featuresDesc4: "مساحة عمل شهرية مدفوعة الأجر",
  featuresDesc5: "معهد تعلم تكنولوجيا المعلومات",
  featuresDesc6: "التسويق الرقمي",

  //Footer
  title1: "خدمتنا",
  title2: "اتصال",
  title3: "عنواننا",
  footerDesc:
    "نحاول دائمًا تنفيذ أفكارنا الإبداعية على أعلى مستوى. أخبرنا عن مشروعك وسوف نجعله يعمل",
  address:
    "المقر الرئيسي: المدينة الإيطالية 1 ، فيلا 103 ، أربيل ، العراق ، صالة العرض: طريق 100 م ، بجانب مدرسة علاء الثانوية ، أربيل ، العراق",
  timeOfWorks: "السبت - الخميس: 9 صباحًا - 5 مساءً الجمعة: عطلة",
  cr: "حقوق النشر @ 2022 Techeye. صمم بواسطة",
  team: "فريق برمجيات Techeye",

  //dashboard
  ourClient: "عملاؤنا",
  ourProducts: "منتجاتنا",
  jumbotron: "جامبوترون",
  ourServices: "خدماتنا",
  infoArea: "منطقة المعلومات",
  contactUs: "اتصل بنا",
  add: "إضافة",
  list: "قائمة",
  edit: "تحرير",
  delete: "حذف",
  noData: "لا توجد بيانات متاحة",
  loading: "تحميل ...",
  error: "خطأ، حاول مرة أخرى لاحقا.",
  previous: "السابق",
  next: "التالي",
  no: "الرقم",
  titleKu: "اللقب الكردي",
  titleAr: "العنوان العربي",
  titleEn: "العنوان باللغة الإنجليزية",
  descKu: "وصف كردي",
  descAr: "الوصف العربي",
  descEn: "الوصف باللغة الإنجليزية",
  attachment: "المرفقات",
  phoneNumber: "رقم الهاتف",
  backToDashbaord: "العودة إلى لوحة القيادة",
  clientImage: "صورة العميل",
};
