import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AddClient from "./AddClient";
import app from "../../../firebase";
import { getDoc, doc, getFirestore } from "firebase/firestore/lite";
import { clients } from "./../../../helpers/collectionNames";

export default function EditClient() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [state, setState] = useState({});

  const getOneClient = async () => {
    const db = getFirestore(app);

    const docRef = doc(db, clients, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setState({
        id: data.id,
        titleKu: data.titleKu,
        titleAr: data.titleAr,
        titleEn: data.titleEn,
        phone: data.phone,
        image: data.image,
        date: data.date,
      });
    } else {
      setError(true);
      navigate("/admin/list-clients");
    }
  };

  useEffect(() => {
    getOneClient();
  }, []);

  return (
    <div>
      <AddClient state={state} />
    </div>
  );
}
