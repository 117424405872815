import DefaultLogo from "./../Assets/logo.png";
import MainLogo from "./../Assets/logos/main.png";
import MainWhiteLogo from "./../Assets/logos/mainWhite.png";
import MainSmallLogo from "./../Assets/logos/mainSmall.png";
import MainSmallWhiteLogo from "./../Assets/logos/mainSmallWhite.png";
import Shape1 from "./../Assets/Images/Shape/1.png";
import Shape2 from "./../Assets/Images/Shape/2.png";
import Shape3 from "./../Assets/Images/Shape/3.png";
import Shape4 from "./../Assets/Images/Shape/4.png";
import Shape5 from "./../Assets/Images/Shape/5.png";
import Shape6 from "./../Assets/Images/Shape/6.png";

const helpers = {
  getImageUrl: (url = null) => {
    if (url) {
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      } else {
        return process.env.REACT_APP_BASE_URL + url;
      }
    }
    return DefaultLogo;
  },
  mainLogo: (white = false) => {
    return white ? MainWhiteLogo : MainLogo;
  },
  mainSmallLogo: (white = false) => {
    return white ? MainSmallWhiteLogo : MainSmallLogo;
  },
  getShape: (shapeNumber = 1) => {
    switch (shapeNumber) {
      case 1:
        return Shape1;
      case 2:
        return Shape2;
      case 3:
        return Shape3;
      case 4:
        return Shape4;
      case 5:
        return Shape5;
      case 6:
        return Shape6;
      default:
        return Shape1;
    }
  },
  getTitleByLang: (props) => {
    if (window.lang.resolvedLanguage == "ku") {
      return props.titleKu;
    } else if (window.lang.resolvedLanguage == "ar") {
      return props.titleAr;
    } else {
      return props.titleEn;
    }
  },
  getDescrByLang: (props) => {
    if (window.lang.resolvedLanguage == "ku") {
      return props.descKu;
    } else if (window.lang.resolvedLanguage == "ar") {
      return props.descAr;
    } else {
      return props.descEn;
    }
  },
  getDescrByLengtth: (props, lenght = 120) => {
    return props.substring(0, lenght) + " ...";
  },
  getAnimationStyle: (index) => {
    if (index == 0) {
      return "fade-right";
    } else if (index == 1) {
      return "fade-left";
    } else if (index == 2) {
      return "fade-up";
    } else if (index == 3) {
      return "fade-down";
    } else if (index == 4) {
      return "fade-left";
    } else if (index == 5) {
      return "fade-right";
    } else {
      return index == 1 ? "fade-up" : "fade-down";
    }
  },
};

export default helpers;
