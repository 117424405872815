export const en = {
  home: "Home",
  about: "About",
  // info area
  ourMission: "Our Mission",
  ourVision: "Our Vision",
  aboutUs: "About Us",

  //services
  servicesTitle: "Smart Services",
  solutions: "Solutions",
  servicesTitleBig: "Techeye Provide All Kind Of Tech Solutions",
  servicesDesc:
    "We help amazing companies build awesome products, support to all customers who trust us and walk with us on this path.",

  // process
  processTitle: "Working Process",
  processTitleBig: "We Are Popular Because Of Our Way Of Working",
  processDesc:
    "You may be amazed by what we can do. We offer many services which you can find below.",
  processChildTitle1: "Research Product",
  processChildTitle2: "User Testing",
  processChildTitle3: "Development",
  processChildTitle4: "Product Handover",
  processChildDesc1:
    "Market size can be difficult to determine but with some educated guessing you can probably get a good idea. For example, a product that caters to pregnant females between 25 and 40 years old probably has a sufficient market.",
  processChildDesc2:
    "User testing is the process through which the interface and functions of a website, app, product, or service are tested by real users who perform specific tasks in realistic conditions.",
  processChildDesc3:
    "Software development refers to a set of computer science activities dedicated to the process of creating, designing, deploying and supporting software.",
  processChildDesc4:
    "After finishing a product as scheduald in decontract, our team will hanover the product to the client.",

  //arae
  araeTitle: "Choose Techeye",
  araeTitleBig: "We Achieved Peoples Trust By Our Great Service",
  areaChildTitle1: "Understanding",
  areaChildTitle2: "Best Quality",
  areaChildTitle3: "Technology",
  areaChildTitle4: "Support 24/7",
  areaChildTitle5: "Expert Team",
  areaChildTitle6: "Price Oriented",

  //Technology
  technologyTitle: "We Are Experts In Our Field",
  technologyDesc1:
    "Under a unique banner, but a super connotation, which is “Technology is Our World,” Tech Eye started as one of the local leading projects in the Iraqi Kurdistan Region to fulfill and provide the technological needs and services.",
  technologyDesc2:
    "What make us an outstanding company is a progressed and developed company in the field of Information Technology. We provide Services like Web Design, Data-base Networking, Web Development, Server, Web Host, and Digital Marketing, to name a few.",

  //Our Products
  productTitle: "Our Products",
  productTitleBig: "Our Latest Products",
  productDesc:
    "We always try to implement creative ideas at the highest level. You can see it by looking at our portfolio.",

  //Clients
  clientTitle: "Clients",
  clientTitleBig: "Who Are Our Clients",
  clientDesc:
    "These are people who trust us and will be hiring us whenever they have a project that needs to be worked on.",

  //Contact Us
  contactUsTitle: "Contact Us",
  contactUsTitleBig: "Let Us Know About Your Project Idea!",
  contactUsDesc:
    "It is a long established fact that a reader will be distracted by the rea dable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more.",
  contactUsChildInput1: "Your Name",
  contactUsChildInput2: "Your Email",
  contactUsChildInput3: "Your Phone",
  contactUsChildInput4: "Subject",
  contactUsChildInput5: "Your Message",
  contactUsChildInput6: "Submit",
  contactUsSending: "Sending ...",

  //Technology
  ourCompanyTitle: "Our Company",
  ourCompanyTitleBig: "Why Choose us",
  ourCompanyDesc1:
    "The dedication to the customers remains our prime concern. We plan to deliver on schedule, in budgets and according to your standards. Our success is primed by transparency and professionalism. The principles are focused on ensuring the team always responds to the needs of our customers.",
  ourCompanyDesc2:
    "We help amazing companies build awesome products, support to all customers who trust us and walk with us on this path.",

  //Features
  featuresTitle: "Features",
  featuresTitleBig: "We Have Also Some Features That Provided by TechEye",
  featuresDesc1: "We are provides this services :",
  featuresDesc2: "Freelancing Traning Course",
  featuresDesc3: "Technological Consultation",
  featuresDesc4: "Monthly Paid Workspace",
  featuresDesc5: "IT Learning Institute",
  featuresDesc6: "Digital Marketing",

  //Footer
  title1: "Our Service",
  title2: "Contact",
  title3: "Our Address",
  footerDesc:
    "We always try to implement our creative ideas at the highest level. Tell us about your project and we will make it work",
  address:
    "Head Quarter: Italian city 1, villa 103 , Erbil, Iraq, Showroom: 100m Road, Next to Ala High School, Erbil, Iraq",
  timeOfWorks: "Sat -Thu : 9 am - 5 pm Fri: Holiday",
  cr: "Copyright @2022 Techeye. Designed By",
  team: "Techeye Software Team",

  // dashboard
  ourClient: "Our Clients",
  ourProducts: "Our Products",
  jumbotron: "Jumbotron",
  ourServices: "Our Services",
  infoArea: "Info Area",
  contactUs: "Contact Us",
  add: "Add",
  list: "List",
  edit: "Edit",
  delete: "Delete",
  noData: "No Data Available",
  loading: "Loading ...",
  error: "Error, try again later.",
  previous: "Previous",
  next: "Next",
  no: "No",
  titleKu: "Kurdish Title",
  titleAr: "Arabic Title",
  titleEn: "English Title",
  descKu: "Kurdish Description",
  descAr: "Arabic Description",
  descEn: "English Description",
  attachment: "Attachment",
  phoneNumber: "Phone Number",
  backToDashbaord: "Back to Dashboard",
  clientImage: "Client Image",
};
