import React, { useEffect, useState, useRef } from "react";
import { products } from "../../helpers/collectionNames";
import app from "./../../firebase.js";
import { useTranslation } from "react-i18next";
import {
  getFirestore,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore/lite";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./css/product.css";

// import required modules
import { Pagination } from "swiper";

function Team() {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    const db = getFirestore(app);
    const docSnap = await getDocs(collection(db, products));
    const tempData = docSnap.docs.map((e) => {
      return e.data();
    });
    tempData.sort((b, a) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));
    setAllProducts(tempData);
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="home-team-area pt-100 pb-100" id="our-products">
      <div className="container-fluid">
        <div className="section-title">
          <span>{window.t("productTitle")}</span>
          <h2>{window.t("productTitleBig")}</h2>
          <p>{window.t("productDesc")}</p>
        </div>

        {allProducts.length == 0 ? null : (
          <Swiper
            slidesPerView={5}
            dir={i18n.language == "en" ? "ltr" : "rtl"}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="myProductSwiper pb-5"
          >
            {allProducts &&
              allProducts.map((e, index) => (
                <SwiperSlide>
                  <div className="home-team-slider">
                    <div className="single-team" key={index}>
                      <div className="team-img">
                        <img src={e.image} alt="team" />
                      </div>
                      <div className="content text-center">
                        <p style={{ fontSize: "18px", color: "#1b96d3" }}>
                          <b>{e.titleKu}</b>
                        </p>
                        <p>{e.titleKu}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
    </section>
  );
}

export default Team;
