import React, { useEffect, useState } from "react";
import "./Admin.css";
import app from "./../../firebase";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { uid } from "uid";

function Admin() {
  const [state, setState] = useState({
    email: "",
    password: "",
    error: false,
    isLoading: false,
  });
  const auth = getAuth(app);

  const login = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, state.email, state.password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("user = ", user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  return (
    <div className="login">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-5">
            <form className="loginForm shadow p-4" onSubmit={login}>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label ">
                  Email address
                </label>
                <input
                  type="email"
                  onChange={(e) => {
                    setState({
                      ...state,
                      email: e.target.value,
                    });
                  }}
                  className="form-control radius"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control radius"
                  id="exampleInputPassword1"
                  onChange={(e) => {
                    setState({
                      ...state,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
              <button
                disabled={state.isLoading}
                type="submit"
                className="btn btn-primary w-100"
              >
                Login
              </button>
              <p className="text-center" v-show="isLoading">
                {state.isLoading ? window.t("loading") : ""}
              </p>
              {state.error ? (
                <p className="text-danger text-center" v-show="error">
                  Error in Email Or Password
                </p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
