export const ku = {
  home: "سەرەكی",
  about: "دەربارە ",
  // info area
  ourMission: "ئەرکی ئێمە",
  ourVision: "دیدگای ئێمە",
  aboutUs: "دەربارەی ئێمە",

  //services
  servicesTitle: "خزمەتگوزاری زیرەک",
  solutions: "چارەسەرەکان",
  servicesTitleBig: "Techeye هەموو جۆرە چارەسەرێکی تەکنەلۆژیا دابین دەکات",
  servicesDesc:
    "ئێمە یارمەتی کۆمپانیا سەرسوڕهێنەرەکان دەدەین بۆ دروستکردنی بەرهەمی سەرسوڕهێنەر، پشتگیری بۆ هەموو ئەو کڕیارانە دەکەین کە متمانەمان پێدەکەن و لەگەڵماندا دەڕۆن لەسەر ئەم ڕێگایە.",

  // process
  processTitle: "پرۆسەی کارکردن",
  processTitleBig: "ئێمە بەهۆی شێوازی کارکردنمانەوە بەناوبانگین",
  processDesc:
    "لەوانەیە سەرت سوڕمابێت لەوەی کە ئێمە دەتوانین چی بکەین. ئێمە چەندین خزمەتگوزاری پێشکەش دەکەین کە دەتوانن لە خوارەوە بیدۆزنەوە.",
  processChildTitle1: "بەرهەمی توێژینەوە",
  processChildTitle2: "تاقیکردنەوەی بەکارهێنەر",
  processChildTitle3: "پەرەپێدان",
  processChildTitle4: "ڕادەستکردنی بەرهەم",
  processChildDesc1:
    "قەبارەی بازاڕ دەتوانێت دیاریکردنی قورس بێت بەڵام بە هەندێک پێشبینی پەروەردەکراو بە ئەگەرێکی زۆرەوە دەتوانیت بیرۆکەیەکی باش بەدەست بهێنیت. بۆ نموونە بەرهەمێک کە خزمەت بە مێینە دووگیانەکانی نێوان ٢٥ بۆ ٤٠ ساڵ بکات، ڕەنگە بازاڕێکی پێویستی هەبێت.",
  processChildDesc2:
    "تاقیکردنەوەی بەکارهێنەر ئەو پرۆسەیەیە کە لە ڕێگەیەوە ڕووکار و کارەکانی ماڵپەڕێک، ئەپێک، بەرهەمێک، یان خزمەتگوزارییەک لەلایەن بەکارهێنەرانی ڕاستەقینەوە تاقیدەکرێتەوە کە ئەرکی تایبەت لە بارودۆخێکی واقیعیدا ئەنجام دەدەن.",
  processChildDesc3:
    "پەرەپێدانی نەرمەکاڵا ئاماژەیە بۆ کۆمەڵێک چالاکیی زانستی کۆمپیوتەر کە تایبەتە بە پرۆسەی دروستکردن و دیزاینکردن و بڵاوکردنەوە و پشتگیریکردنی نەرمەکاڵا.",
  processChildDesc4:
    "دوای تەواوکردنی بەرهەمێک وەک لە گرێبەستدا دیاری کراوە، تیمەکەمان بەرهەمەکە ڕادەستی کڕیار دەکات.",

  //arae
  araeTitle: "Techeye هەڵبژێرە",
  araeTitleBig: "ئێمە متمانەی گەلانمان بەدەستهێنا بە خزمەتە گەورەکەمان",
  areaChildTitle1: "تێگەیشتن",
  areaChildTitle2: "باشترین جۆر",
  areaChildTitle3: "تەکنەلۆجیا",
  areaChildTitle4: "پشتگیری 24/7",
  areaChildTitle5: "تیمی پسپۆڕ",
  areaChildTitle6: "نرخ ئاراستەکراو",

  //Technology
  technologyTitle: "ئێمە شارەزاین لە بوارەکەماندا",
  technologyDesc1:
    "لە ژێر لافیتەیەکی ناوازە، بەڵام واتایەکی سوپەر، کە بریتییە لە 'تەکنەلۆژیا جیهانی ئێمەیە', تەک ئای وەک یەکێک لە پڕۆژە پێشەنگەکانی ناوخۆیی لە هەرێمی کوردستانی عێراق دەستیپێکرد بۆ بەدیهێنان و پێشکەشکردنی پێداویستی و خزمەتگوزارییە تەکنەلۆژییەکان.",
  technologyDesc2:
    "ئەوەی ئێمە دەکاتە کۆمپانیایەکی نایاب کۆمپانیایەکی پێشکەوتوو و پێشکەوتووە لە بواری تەکنەلۆژیای زانیاری. ئێمە خزمەتگوزارییەکانی وەک وێب دیزاین، تۆڕی بنکەدراوە، گەشەپێدانی وێب، سێرڤەر، وێب هۆست، و بەبازاڕکردنی دیجیتاڵی پێشکەش دەکەین، بۆ ناوهێنانی چەند دانەیەک.",

  //Our Products
  productTitle: "بەرهەمەکانمان",
  productTitleBig: "نوێترین بەرهەمەکانمان",
  productDesc:
    "ئێمە هەمیشە هەوڵ دەدەین بیرۆکەی داهێنەرانە لە بەرزترین ئاستدا جێبەجێ بکەین. دەتوانن بە سەیرکردنی پۆرتفۆلیۆکەمان بیبینن.",

  //Clients
  clientTitle: "کڕیاران",
  clientTitleBig: "کڕیارەکانمان کێن",
  clientDesc:
    "ئەمانە کەسانێکن کە متمانەیان بە ئێمە هەیە و هەرکاتێک پرۆژەیەکیان هەبێت کە پێویستی بە کاری لەسەر بکرێت، بەکرێمان دەگرن.",

  //Contact Us
  contactUsTitle: "پەیوەندیمان پێوەبکە",
  contactUsTitleBig: "با لەبارەی بیرۆکەی پرۆژەکەتەوە بزانین!",
  contactUsDesc:
    "ئەوە ڕاستییەکی لەمێژە جێگیرە کە خوێنەر لە کاتی سەیرکردنی شێوازی لاپەڕەیەکدا بەهۆی ناوەڕۆکی خوێنەرەوەی لاپەڕەیەکەوە سەرقاڵ دەبێت. خاڵی بەکارهێنانی Lorem Ipsum ئەوەیە کە زیاتر...",
  contactUsChildInput1: "ناوت",
  contactUsChildInput2: "ئیمەیڵەکەت",
  contactUsChildInput3: "ژماره‌كه‌ت",
  contactUsChildInput4: "بابەت",
  contactUsChildInput5: "نامه‌كانت",
  contactUsChildInput6: "پێشکەشکردن",
  contactUsSending: "ناردن ...",

  //Technology
  ourCompanyTitle: "کۆمپانیاکەمان",
  ourCompanyTitleBig: "بۆچی ئێمە هەڵبژێرە",
  ourCompanyDesc1:
    "تەرخانکردن بۆ کڕیارەکان وەک خەمی سەرەکی ئێمە دەمێنێتەوە. ئێمە پلانمان هەیە کە بە خشتە، لە بودجە و بەپێی ستانداردەکانی ئێوە بگەیەنین. سەرکەوتنمان بە شەفافیەت و پیشەگەرێتییەوە سەرکەتوو دەبێت پرەنسیپەکان جەخت لەسەر ئەوە کراوەتەوە کە تیمەکە هەمیشە وەڵامی پێویستی کڕیارەکانمان دەداتەوە.",
  ourCompanyDesc2:
    "ئێمە یارمەتی کۆمپانیا سەرسوڕهێنەرەکان دەدەین کە بەرهەمی نایاب دروست بکەن، پشتگیری هەموو ئەو کڕیارانە بکەن کە باوەڕمان پێ دەکەن و لەسەر ئەم ڕێگایە لەگەڵمان دەڕۆن.",

  //Features
  featuresTitle: "تایبەتمەندیەکان",
  featuresTitleBig:
    "هەروەها هەندێک تایبەتمەندیمان هەیە کە لەلایەن تەکیی دابین کراوە",
  featuresDesc1: "ئێمە ئەم خزمەتگوزاریانە دابین دەکەین:",
  featuresDesc2: "خولی ئازادی ترانینگ",
  featuresDesc3: "ڕاوێژی تەکنەلۆجیا",
  featuresDesc4: "سپەیس کاری مانگانەی پارەدراو",
  featuresDesc5: "پەیمانگای فێربوونی IT",
  featuresDesc6: "بازاڕکردنی دیجیتاڵ",

  //Footer
  title1: "خزمەتگوزاری ئێمە",
  title2: "پەیوەندی",
  title3: "ناونیشانمان",
  footerDesc:
    "ئێمە هەمیشە هەوڵ دەدەین بیرۆکەی داهێنەرانەمان لە بەرزترین ئاستدا جێبەجێ بکەین. باسی پڕۆژەکەتمان بۆ بکە ئێمە وا دەکەین کار بکات",
  address:
    "بارەگای سەرەکی: شاری ئیتاڵی 1, ڤێلای 103 ، هەولێر، عێراق، پێشانگا: جادەی 100م، تەنیشت ئامادەیی ئاڵا، هەولێر، عێراق",
  timeOfWorks: "شەممە -پێنجشەممە : 9 بەیانی - 5 ئێوارە هەینی: پشوو",
  cr: "مافی بڵاوکردنەوە پارێزراوە @2022 Techeye. دیزاین کراوە لەلایەن",
  team: "تیمی نەرمەکاڵای Techeye",

  //dashboard
  ourClient: "کڕیارەکانمان",
  ourProducts: "بەرهەمەکانمان",
  jumbotron: "جومبۆترۆن",
  ourServices: "خزمەتگوزاریەکانمان",
  infoArea: "ناوچەی زانیاری",
  contactUs: "پەیوەندیمان پێوە بکە",
  add: "زیادکردن",
  list: "لیست",
  edit: "بژارکردن",
  delete: "سڕینەوە",
  noData: "داتا بەردەست نیە",
  loading: "بارکردنی ...",
  error: "هەڵە، دواتر هەوڵ بدەوە",
  previous: "پێشوو",
  next: "دواتر",
  no: "ژمارە",
  titleKu: "ناونیشانی کورد",
  titleAr: "ناونیشانی عەرەبی",
  titleEn: "ناونیشانی ئینگلیزی",
  descKu: "وەسفی کورد",
  descAr: "وەسفی عەرەبی",
  descEn: "وەسفی ئینگلیزی",
  attachment: "هاوپێچ",
  phoneNumber: "ژمارەی تەلەفۆن",
  backToDashbaord: "گەڕانەوە بۆ داشبۆرد",
  clientImage: "وێنەی کڕیار",
};
