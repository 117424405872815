import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import app from "../../../firebase";
import { uid } from "uid";
import {
  getFirestore,
  collection,
  Timestamp,
  doc,
  setDoc,
  deleteDoc,
} from "firebase/firestore/lite";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { services } from "../../../helpers/collectionNames";

export default function AddProduct(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState(false);
  const [slectedImg, setSlectedImg] = useState(null);
  const [state, setState] = useState({
    titleKu: "",
    titleAr: "",
    titleEn: "",
    descKu: "",
    descEn: "",
    descAr: "",
    image: "",
    date: Timestamp.now(),
  });
  const navigate = useNavigate();

  const setImage = (e) => {
    var files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    setSlectedImg(files[0]);
  };

  const addService = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let image = null;
    const storage = getStorage(app);
    const id = isEdit ? state.id : uid(16);
    if (slectedImg != null && slectedImg) {
      const storageRef = ref(storage, id + ".png");
      let snapshot = await uploadBytes(storageRef, slectedImg);
      image = await getDownloadURL(snapshot.ref);
    }

    const db = getFirestore(app);
    const groceriesColRef = doc(collection(db, services), id);
    return setDoc(groceriesColRef, {
      id: id,
      ...state,
      image: image ?? state.image,
    })
      .then(() => {
        setIsLoading(false);
        navigate("/admin/list-services");
      })
      .catch((err) => {
        setIsLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (
      props.state != undefined &&
      props.state.id != undefined &&
      props.state.id != "" &&
      props.state.id != null
    ) {
      setState(props.state);
      setIsEdit(true);
      setIsLoading(false);
    }
  }, [props]);

  return (
    <div className="container my-5 py-5">
      <div className="d-flex justify-content-between align-items-center">
        <h1>
          {!isEdit ? window.t("add") : window.t("edit")}{" "}
          {window.t("ourServices")}
        </h1>
        <Link to="/dashboard" className="btn btn-primary radius">
          {window.t("backToDashbaord")}
        </Link>
      </div>
      <hr />
      <div className="row d-flex justify-content-center">
        <div className="col-lg-8">
          <form onSubmit={addService}>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("titleKu")}
              </label>
              <input
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    titleKu: e.target.value,
                  });
                }}
                value={state.titleKu}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("titleAr")}
              </label>
              <input
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    titleAr: e.target.value,
                  });
                }}
                value={state.titleAr}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("titleEn")}
              </label>
              <input
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    titleEn: e.target.value,
                  });
                }}
                value={state.titleEn}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("descKu")}
              </label>
              <textarea
                rows="12"
                cols="40"
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    descKu: e.target.value,
                  });
                }}
                value={state.descKu}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("descAr")}
              </label>
              <textarea
                rows="12"
                cols="40"
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    descAr: e.target.value,
                  });
                }}
                value={state.descAr}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {window.t("descEn")}
              </label>
              <textarea
                rows="12"
                cols="40"
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    descEn: e.target.value,
                  });
                }}
                value={state.descEn}
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                {window.t("clientImage")}
              </label>
              <input
                onChange={setImage}
                type="file"
                className="form-control"
                id="image"
              />
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-secondary w-100"
            >
              {!isEdit ? window.t("add") : window.t("edit")}
            </button>
            {isLoading ? (
              <p className="text-center" v-show="isLoading">
                {window.t("loading")}
              </p>
            ) : (
              ""
            )}
            {error ? (
              <p className="text-danger text-center" v-show="error">
                Error
              </p>
            ) : (
              ""
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
