import React, { useEffect, useState, useRef } from "react";
import helpers from "./../../Constants/helpers";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import "./Language.css";
import { Link } from "react-router-dom";

function Header() {
  const { i18n } = useTranslation();
  var headerContentStyle = classNames("header-content-right", {
    "header-content-right": i18n.resolvedLanguage == "en",
    "header-content-rtl":
      i18n.resolvedLanguage == "ku" || i18n.resolvedLanguage == "ar",
  });
  return (
    <header className="header-area">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2 col-sm-0">
            <div className="logo">
              <Link to={"/"}>
                <img src={helpers.mainLogo()} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-8 col-sm-8 text-right pr-0">
            <div className={headerContentStyle}>
              <ul className="header-contact">
                <li>
                  <a href="tel:+964 750 451 5480">
                    <i className="bx bxs-phone-call"></i> +964 750 451 5480
                  </a>
                </li>
                <li>
                  <a href="mailto:info@tech-eye.com">
                    <i className="bx bxs-envelope"></i> info@tech-eye.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-4 text-right pl-0">
            <div className={headerContentStyle}>
              <ul className="header-social">
                <li>
                  <a
                    href="https://www.facebook.com/techeye.org/"
                    target="_blank"
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/techeyeorg" target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="mailto:info@tech-eye.com" target="_blank">
                    <i className="bx bxs-envelope"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCsVd1oRsbks6oUu3Oo8ZiuQ"
                    target="_blank"
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
