import React, { useEffect, useState, useRef } from "react";
import { Outlet } from "react-router-dom";
// import PreLoader from "./Shared/PreLoader";
import Header from "./Shared/Header";
import Navbar from "./Shared/Navbar";
import MobileNavbar from "./Shared/MobileNavbar";
import Footer from "./Shared/Footer";

function Layout() {
  return (
    <main>
      {/* <PreLoader /> */}
      <div className="d-none d-md-block d-lg-block">
        <Header />
        <Navbar />
      </div>
      <div className="d-block d-md-none d-lg-none">
        <MobileNavbar />
      </div>
      <Outlet />
      <Footer />
    </main>
  );
}

export default Layout;
